import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MasterPageComponent } from './MasterPageComponent';
import { HomeComponent } from './HomeComponent';
import{MainRoutingModule} from '../Routing/MainRouting';

@NgModule({
  declarations: [
    MasterPageComponent,HomeComponent
  ],
  imports: [     
    BrowserModule,
    FormsModule,
    MainRoutingModule           
  ],
  providers: [],
  bootstrap: [MasterPageComponent]
})
export class MainModule { }