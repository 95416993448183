<header id="header" class="fixed-top d-flex align-items-center">
  <div id="navheader" class="container-fluid container-xl d-flex align-items-center justify-content-between">
    <a href="index.html" class="logo d-flex align-items-center">
      <img class="img-logo" src="assets/img/Logo.png" alt="">
    </a>
    <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
    <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
    <div class="row">
      <div class="justify-content-end d-flex" style="font-size: 0.8rem;">
        <span class="d-flex"><i class="bi bi-envelope-fill"></i>&nbsp;&nbsp;Info@nmsforum.org &nbsp;</span>
        <span class="d-flex"><i class="bi bi-telephone-fill"></i>&nbsp;+44 7961344575 &nbsp;</span>               
        <span><a href="https://www.facebook.com/groups/nmsforum/" target="_blank"
           class="d-flex align-items-center justify-content-center"><i class="bi bi-facebook"></i></a></span>
        <span><a href="https://twitter.com/NewhamMSF" target="_blank" 
          class="d-flex align-items-center justify-content-center"><i class="bi bi-twitter"></i></a></span>
        <span><a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-youtube" style="font-size: 1rem;"></i></a></span>
        <span><a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-linkedin"></i></a></span>
      </div>
      <div>
        <nav id="navbar" class="navbar">
          <ul style="margin-left: auto;">
            <li><a class="nav-link active" id="Home" [routerLink]="['Home']">Home</a></li>
            <li><a class="nav-link" id="Event" [routerLink]="['Event/Add']">Event</a></li>           
            <li class="dropdown"><a><span>Registration</span> <i
                  class="bi bi-chevron-down dropdown-indicator"></i></a>
              <ul>
                <li><a class="nav-link" [routerLink]="['Food/Add']">Food</a></li>
                <li><a class="nav-link" [routerLink]="['Volunteer/Add']">Volunteer</a></li>
              </ul>
            </li>
            <li><a class="nav-link" id="About" [routerLink]="['About/Add']">About</a></li>
            <li><a class="nav-link" id="Contact" [routerLink]="['Contact/Add']">Contact</a></li>
            <li><a class="nav-link" id="Donate" [routerLink]="['Donate/Add']">Donate</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
<div>
  <router-outlet></router-outlet>
</div>
<footer id="footer" class="footer">
  <div class="footer-content position-relative">
      <div class="container">
          <div class="row">
              <div class="col-lg-4 col-md-6">
                  <div class="footer-info">
                      <h3>Newham Muslim &nbsp; Safety Forum</h3>
                      <p>    
                        250 - 252 High Street<br>                        
                        North Manor Park<br>
                        London<br>
                        E12 6SB<br><br>
                          <span>Phone:</span> +44 7961344575<br>
                          <span>Email:</span> Info@nmsforum.org<br>
                      </p>
                      <div class="social-links d-flex mt-3">
                          <a href="https://twitter.com/NewhamMSF" target="_blank" 
                          class="d-flex align-items-center justify-content-center"><i class="bi bi-twitter"></i></a>
                          <a href="https://www.facebook.com/groups/nmsforum/" target="_blank" 
                          class="d-flex align-items-center justify-content-center"><i class="bi bi-facebook"></i></a>
                          <a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-youtube" style="font-size: 25px;"></i></a>
                          <a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-linkedin"></i></a>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-4 footer-links">
                <h4>Donate</h4>
                <p>    
                  For Food Bank Donation ,<br>                        
                  Please deposit in our account below<br>
                  Newham Muslim Safety Forum<br><br>
                  <span>Lloyds Bank</span><br>
                  <span>Sort Code: 30-98-97</span><br>
                  <span>Account: 53262062</span><br>
                </p>                  
              </div>
              <div class="col-lg-3 col-md-4 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><a [routerLink]="['Home']">Home</a></li>
                  <li><a [routerLink]="['Food/Add']">Food Registration</a></li>
                  <li><a [routerLink]="['Volunteer/Add']">Volunteer Registration</a></li>
                  <li><a [routerLink]="['About/Add']">About</a></li>
                  <li><a [routerLink]="['Contact/Add']">Contact</a></li>
                  <li><a [routerLink]="['Donate/Add']">Donate</a></li>
                </ul>
              </div>
              <div class="col-lg-2 col-md-3 footer-links">
                <h4>Events</h4>
                <ul>
                    <li><a>Event 1</a></li>
                    <li><a>Event 2</a></li>
                    <li><a>Event 3</a></li>
                    <li><a>Event 4</a></li>                      
                </ul>
              </div>
          </div>
      </div>
  </div>

  <div class="footer-legal text-center position-relative">
      <div class="container">
          <div class="copyright">
              &copy; Copyright <span>Newham Muslim Safety Forum</span>. All Rights Reserved
          </div>
          <div class="credits">
               <p>Designed by <span>The3031Theory</span></p>
          </div>
      </div>
  </div>
</footer> 
<a class="scroll-top d-flex align-items-center justify-content-center" (click)="scrollToTop();"><i class="bi bi-arrow-up-short"></i></a>

