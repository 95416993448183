import { Component } from '@angular/core';
import { Router } from '@angular/router';
import AOS from "aos";
@Component({
  selector: 'app-root',
  templateUrl: './MasterPageView.html'  
})
export class MasterPageComponent{
  ngOnInit() {
    AOS.init();
  }
  scrollToTop= function () {       
    window.scrollTo(0, 0);
  };
}