import { Component, NgModule } from '@angular/core';
import { RouterModule,Routes} from '@angular/router';
import { HomeComponent } from '../Home/HomeComponent';

const appRoutes: Routes = [
     {path:'Home', component: HomeComponent},  
     {path:'Event', loadChildren: () => import('../Event/EventModule').then(m => m.EventModule)},   
     {path:'Food', loadChildren: () => import('../Food/FoodModule').then(m => m.FoodModule)},
     {path:'Volunteer', loadChildren: () => import('../Volunteer/VolunteerModule').then(m => m.VolunteerModule)},
     {path:'About', loadChildren: () => import('../About/AboutModule').then(m => m.AboutModule)},  
     {path:'Contact', loadChildren: () => import('../Contact/ContactModule').then(m => m.ContactModule)}, 
     {path:'Donate', loadChildren: () => import('../Donation/DonationModule').then(m => m.DonationModule)},          
     {path:'', component: HomeComponent}
]

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]   
   })
   export class MainRoutingModule { }

