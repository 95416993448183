import { Component } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({ 
  templateUrl: './HomeView.html'  
})
export class HomeComponent{ 
  constructor(private router:Router){}
  currentDate: Date 
  ngOnInit(){         
    this.currentDate = moment().isoWeekday(6).toDate();              
  }   
  goToDonate= function () {
    this.router.navigateByUrl('/Donate/Add');
  };
  goToEvent= function () {
    this.router.navigateByUrl('/Event/Add');
  };
}
