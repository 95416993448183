<div class="PageSpacing">
  <!-- # Information About the Forum -->
  <section id="alt-Info" class="alt-Info">
    <div class="container" data-aos="fade-up" data-aos-easing="linear">
      <div class="row justify-content-around gy-4">
        <div class="col-lg-7 img-bg hero" id="hero">
          <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
            <div class="gradient-layer carousel-item active"
              style="background-image: url(assets/img/hero-carousel/carousel-1.jpg)"></div>
            <div class="carousel-item" style="background-image: url(assets/img/hero-carousel/carousel-2.jpg)"></div>
            <div class="carousel-item" style="background-image: url(assets/img/hero-carousel/carousel-3.jpg)"></div>
            <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
              <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
            </a>
            <a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
              <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
            </a>
          </div>
        </div>
        <div class="col-lg-4 d-flex flex-column justify-content-center">
          <h3>Newham Muslim Safety Forum</h3>
          <p>We formed this forum in 2019 to represent mosques, community groups and activists
            working to protect and ensure the safety of Muslims living in the Borough. We are passionate about working
            to promote cohesiveness and diversity and we pride ourselves on our
            work with representatives of other faith groups working for the common good of all of Newham’s communities.
          </p>
          <p>
            Our priority at the moment is helping the impoverished and vulnerable people living in the Borough,
            through the cost of living crisis. Nearly 37% of children in Newham live in poverty and we have the
            highest rates of malnutrition and hunger in London. This food bank started at the beginning of pandemic
            Covid -19 weeks to distribute essential food items to the disadvantaged, free of charge.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- # Information About the Services We Do -->
  <section id="Things" class="Things">
    <div class="container">
      <div class="section-header" data-aos="zoom-in" data-aos-easing="ease-in-back"
      data-aos-delay="100" data-aos-offset="0"> 
        <h2>Things We Do</h2>
        <p>The local community, mosques and businesses have come together to help and support those with little or
          nothing to eat. Newham has the highest child poverty in the country. Let's support these children.
        </p>
      </div>
      <div class="row gy-4">
        <div class="col-lg-6" data-aos="fade-right" data-aos-delay="200">
          <div class="card-item">
            <div class="row">
              <div class="col-xl-5">
                <div class="card-bg" style="background-image: url(assets/img/Events.jpg);"></div>
              </div>
              <div class="col-xl-7 d-flex align-items-center">
                <div class="card-body">
                  <h4 class="card-title">Events</h4>
                  <p>
                    <span class="badge">SATURDAY <br>
                      {{currentDate | date:'dd-MM-yyyy'}}</span><br>
                    <span>Food distribution starts from 5pm outside <br>
                      252 High Street North<br>
                      Corner of Gladstone Ave<br>
                      London E12 6SB.<br></span>
                  </p>
                  <hr>
                  <a class="readmore" id="Event" (click)="goToEvent();"><span>Read More </span><i
                      class="bi bi-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div><!-- End Card Item -->
        <div class="col-lg-6" data-aos="fade-left" data-aos-delay="200">
          <div class="card-item">
            <div class="row">
              <div class="col-xl-5">
                <div class="card-bg" style="background-image: url(assets/img/Updates.jpg);"></div>
              </div>
              <div class="col-xl-7 d-flex align-items-center">
                <div class="card-body">
                  <h4 class="card-title">Updates</h4>
                  <p>Sunt deserunt maiores voluptatem autem est rerum perferendis rerum blanditiis. Est laboriosam qui
                    iste numquam laboriosam voluptatem architecto. Est laudantium sunt at quas aut hic. Eum dignissimos.
                  </p>
                  <hr>
                  <a class="readmore" id="Update" (click)="goToEvent();"><span>Read More </span><i
                      class="bi bi-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div><!-- End Card Item -->
        <div class="col-lg-6" data-aos="fade-right" data-aos-delay="300">
          <div class="card-item">
            <div class="row">
              <div class="col-xl-5">
                <div class="card-bg" style="background-image: url(assets/img/Donations.jpg);"></div>
              </div>
              <div class="col-xl-7 d-flex align-items-center">
                <div class="card-body">
                  <h4 class="card-title">Donations</h4>
                  <p>The value of life is not in its duration, but in its donation. You are not important because
                    of how long you live, you are important because of how effective you live.
                  </p>
                  <hr><br>
                  <a class="readmore" id="Donate" (click)="goToDonate();"><span class="donate">Donate</span></a>
                </div>
              </div>
            </div>
          </div>
        </div><!-- End Card Item -->
        <div class="col-lg-6" data-aos="fade-left" data-aos-delay="300">
          <div class="card-item">
            <div class="row">
              <div class="col-xl-5">
                <div class="card-bg" style="background-image: url(assets/img/Volunteers.jpg);"></div>
              </div>
              <div class="col-xl-7 d-flex align-items-center">
                <div class="card-body">
                  <h4 class="card-title">Volunteers</h4>
                  <p>You don’t choose the day you enter the world and you don’t chose the day you leave.
                    It’s what you do in between that makes all the difference.
                  </p>
                  <div class="stats-item d-flex align-items-center justify-content-center mt-3">
                    <i class="bi bi-stopwatch color-blue flex-shrink-0"></i>
                    <div >
                      <span>Volunteers Count</span>
                      <span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1"
                        class="purecounter">20</span>                        
                    </div>
                  </div>                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</div>